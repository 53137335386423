/*  React Tinongo <Hits> component */

import React from 'react';
import { Link } from 'gatsby';
import { connectInfiniteHits, connectStats } from 'react-instantsearch-dom';
import { UIContext } from '../../../context/ui';

import Button from '../../button';
import Hit from './hit';

import styles from './hits.module.scss';

/* __<Stats> component__ */
function __Stats({ nbHits }) {
  return (
    <div className={styles.hitsStats}>
      <b>
        {nbHits} {nbHits !== 1 ? 'Vereine' : 'Verein'}
      </b>{' '}
      gefunden. Dein Verein ist nicht dabei?{' '}
      <Link to="/vereine/neu">Hier kannst Du ihn eintragen.</Link>
    </div>
  );
}
const Stats = connectStats(__Stats);

function Hits({ hits, hasMore, refineNext }) {
  return (
    <UIContext.Consumer>
      {(value) => (
        <div className={styles.hits}>
          <Stats />
          {hits.map((hit) => {
            // Not elegant, but working hack... Couldn't find another solution to
            // connect marker and hit when using connectInfiniteHits connector.
            const marker = document.getElementsByClassName(
              `gmap-marker-${hit.objectID}`
            )[0];

            return (
              <Hit
                hit={hit}
                key={hit.objectID}
                onClick={() => {
                  value.closeActionDrawer();
                  return marker ? marker.click() : false;
                }}
              />
            );
          })}
          {hasMore && (
            <div className={styles.hitsShowMore}>
              <span className={styles.hitsShown}>
                {hits.length} {hits.length !== 1 ? 'Vereine' : 'Verein'}
              </span>
              <Button raised="true" onClick={refineNext}>
                <i className="material-icons">arrow_downward</i>
                <span>Mehr</span>
              </Button>
            </div>
          )}
        </div>
      )}
    </UIContext.Consumer>
  );
}
export default connectInfiniteHits(Hits);
