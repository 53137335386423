/*  React Tinongo <Hit> component */

import React from 'react';

import styles from './hit.module.scss';

export default function ({ hit, ...props }) {
  return (
    <div className={styles.hit} {...props}>
      <h3 className={styles.hitTitle}>{hit.verein}</h3>
      <div className={styles.hitContent}>
        <div className={styles.hitAddress}>
          <address>{`${hit.strasse}, ${hit.plz} ${hit.ort}`}</address>
          {hit._geoloc && (
            <a
              className={styles.hitDirections}
              href={`https://www.google.de/maps/dir//${hit._geoloc.lat}, ${hit._geoloc.lng}`}
              target="_blank"
              rel="noopener noreferrer">
              <i className="material-icons">directions</i>
            </a>
          )}
        </div>
        <a
          className={styles.hitLink}
          href={hit.url}
          target="_blank"
          rel="noopener noreferrer">
          {hit.url}
        </a>
      </div>
    </div>
  );
}
