/*  React Tinongo <Hits> component */

import React from 'react';
import { connectStats } from 'react-instantsearch-dom';

/* <Stats> component */
function Stats({ nbHits }) {
  return <>{nbHits}</>;
}
export default connectStats(Stats);
