/*  React Tinongo algolia/clubs/<Facets> component */

import React from 'react';
import { connectToggleRefinement } from 'react-instantsearch-dom';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';

import styles from './facets.module.scss';

const Toggle = connectToggleRefinement(
  ({ currentRefinement, label, count, refine }) => {
    const handleToggle = () => {
      refine(!currentRefinement);
    };

    return (
      <FormControlLabel
        checked={currentRefinement}
        label={
          <>
            {label}{' '}
            <Chip
              clickable={false}
              label={`${
                currentRefinement ? count.checked || 0 : count.unchecked || 0
              }`}
            />
          </>
        }
        control={
          <Switch
            name={label.toLowerCase()}
            size="small"
            onChange={handleToggle}
            value={currentRefinement}
          />
        }
      />
    );
  }
);

/* <Facets> component */
function Facets({ isOpen, onClose, searchState }) {
  const handleDone = (e) => {
    onClose(e);
  };

  return (
    <Dialog
      keepMounted
      disableBackdropClick
      disableEscapeKeyDown
      fullWidth={true}
      maxWidth="sm"
      open={isOpen}
      aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">Ergebnisse filtern</DialogTitle>
      <DialogContent>
        <FormGroup className={styles.facetsSwitches} row>
          <Toggle
            label="KISS"
            attribute="kiss"
            value={true}
            defaultRefinement={
              (searchState.toggle && searchState.toggle.kiss) || false
            }
          />
          <Toggle
            label="Behindertensport"
            attribute="behindertensport"
            value={true}
            defaultRefinement={
              (searchState.toggle && searchState.toggle.behindertensport) ||
              false
            }
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={(e) => handleDone(e)} color="secondary">
          Fertig
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Facets;
