/*  React Tinongo algolia/clubs/<SearchBox> component */

import React from 'react';
import { connectSearchBox } from 'react-instantsearch-dom';
import Chip from '@material-ui/core/Chip';
import ClearIcon from '@material-ui/icons/Clear';
import Facets from './facets';
import FilterListIcon from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import InputLabel from '@material-ui/core/InputLabel';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import NativeSelect from '@material-ui/core/NativeSelect';
import NrOfHits from './nr-of-hits';
import Paper from '@material-ui/core/Paper';
import PlaceIcon from '@material-ui/icons/Place';
import SearchIcon from '@material-ui/icons/Search';
import Snackbar from '../../material-ui/snackbar';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

import styles from './search-box.module.scss';

// MUI Tinongo theme
const theme = createMuiTheme(require('./../../../styles/theme/mui-theme'));

function SearchBox({
  currentRefinement,
  refine,
  state,
  geocoder,
  onRadiusSearch,
}) {
  //
  // --- State ---
  //

  const [what, setWhat] = React.useState(state.query);
  const [where, setWhere] = React.useState('');
  const [facetsOpen, setFacetsOpen] = React.useState(false);
  const [radius, setRadius] = React.useState(10000);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    message: undefined,
    variant: undefined,
  });

  //
  // --- Event listeners ---
  //

  const handleWhatChange = (e) => {
    const val = e.target.value;
    setWhat(val);
    refine(val);
  };

  const handleWhereChange = (e) => {
    setWhere(e.target.value);
    if (e.target.value === '') {
      handleWhereReset();
    }
  };

  const handleWhatReset = () => {
    setWhat('');
    refine('');
  };

  const handleWhereReset = () => {
    setWhere('');
    onRadiusSearch({});
  };

  const handleRadiusChange = (e) => {
    setRadius(e.target.value);
  };

  const handleClickFilterButton = () => {
    setFacetsOpen(true);
  };

  const handleClickRadiusSearch = () => {
    geocoder.geocode({ address: where, region: 'de' }, function (
      results,
      status
    ) {
      if (status === 'OK') {
        const location = results[0].geometry.location;
        const latLng = location.lat() + ', ' + location.lng();

        onRadiusSearch({ latLng, radius });
      } else {
        setSnackbar({
          message: `Umkreissuche für ${where} nicht möglich: ${status}`,
          variant: 'error',
          open: true,
        });
      }
    });
  };

  const handleFacetsDialogClose = (e) => {
    setFacetsOpen(false);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar((prevState) => {
      return {
        ...prevState,
        open: false,
        message: undefined,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  //
  // --- JSX ---
  //

  const searchInput = (
    <>
      <Paper
        className={styles.searchBoxForm}
        component="form"
        noValidate
        action=""
        role="search"
        onSubmit={(e) => handleSubmit(e)}>
        <InputBase
          className={`${styles.searchBoxInput}`}
          placeholder="Suche Sportart oder Verein"
          inputProps={{ 'aria-label': 'Suche Sportart oder Verein' }}
          onChange={(e) => handleWhatChange(e)}
          value={what}
        />
        {currentRefinement && (
          <IconButton aria-label="Reset" size="small" onClick={handleWhatReset}>
            <ClearIcon />
          </IconButton>
        )}
        <hr className={styles.searchBoxInputDivider} />
        <Chip clickable={false} label={<NrOfHits />} icon={<PlaceIcon />} />
        <hr className={styles.searchBoxInputDivider} />
        <IconButton
          color="secondary"
          aria-label="Filter"
          onClick={handleClickFilterButton}>
          <FilterListIcon />
        </IconButton>
      </Paper>
      <Facets
        keepMounted
        isOpen={facetsOpen}
        onClose={handleFacetsDialogClose}
        searchState={state}
      />

      <Paper
        className={styles.searchBoxForm}
        component="form"
        noValidate
        action=""
        role="search"
        onSubmit={(e) => handleSubmit(e)}>
        <LocationSearchingIcon
          className={styles.searchBoxFacetsLocationIcon}
          color="disabled"
        />
        <InputBase
          className={styles.searchBoxInput}
          placeholder="Wo"
          inputProps={{ 'aria-label': 'Wo' }}
          onChange={(e) => handleWhereChange(e)}
          value={where}
        />
        {where && (
          <IconButton
            aria-label="Reset"
            size="small"
            onClick={handleWhereReset}>
            <ClearIcon />
          </IconButton>
        )}
        <hr className={styles.searchBoxInputDivider} />
        <div className={styles.searchBoxRadiusSelect}>
          <FormControl disabled={where ? false : true}>
            <InputLabel htmlFor="radius">Radius</InputLabel>
            <NativeSelect
              value={radius}
              onChange={handleRadiusChange}
              inputProps={{
                name: 'radius',
                id: 'radius',
              }}>
              <option value={10000}>10 km</option>
              <option value={25000}>25 km</option>
              <option value={50000}>50 km</option>
              <option value={100000}>100 km</option>
            </NativeSelect>
          </FormControl>
        </div>
        <hr className={styles.searchBoxInputDivider} />
        <IconButton
          disabled={where ? false : true}
          type="submit"
          className={styles.searchBoxIconButton}
          color="secondary"
          aria-label="Umkreissuche"
          onClick={handleClickRadiusSearch}>
          <SearchIcon />
        </IconButton>
      </Paper>
    </>
  );

  //
  // --- Returned component ---
  //

  return (
    <ThemeProvider theme={theme}>
      <div className={styles.searchBox}>{searchInput}</div>
      <Snackbar
        message={snackbar.message}
        variant={snackbar.variant}
        open={snackbar.open}
        onClose={handleSnackbarClose}
      />
    </ThemeProvider>
  );
}

export default connectSearchBox(SearchBox);
