/*  React Tinongo <Map> component */

import React from 'react';
import { CustomMarker, GeoSearch } from 'react-instantsearch-dom-maps';

import CircularProgress from '@material-ui/core/CircularProgress';

import SVGIcon from '../../svg-icon';

import styles from './map.module.scss';

/**
 * Creates the content for the GMap info window of a club.
 *
 * @param {Object} hit – An Algolia hit object.
 * @return {string} – The HTML markup for a GMap info window of a club.
 */
function infoWindowContent(hit) {
  return `
    <div class=${styles.gMapInfoWindow}>
      <h1 class=${styles.gMapInfoWindowTitle}>
        ${hit.verein}
      </h1>
      <section>
        <h1>Sportarten</h1>
        ${hit.sportarten.join(', ')}
      </section>
      <section>
        <h1>Adresse</h1>
        <address>
          ${hit.strasse}<br />
          ${hit.plz} ${hit.ort}
        </address>
        <a
          class=${styles.gMapInfoWindowLink}
          href="https://www.google.de/maps/dir//${hit._geoloc.lat},${
    hit._geoloc.lng
  }"
          target="_blank" rel="noopener noreferrer"
        >
          <span>Route berechnen</span>
          <i class="material-icons">directions</i>
        </a>
      </section>
      <section>
        <a
          class=${styles.gMapInfoWindowLink}
          href="${hit.url}"
          target="_blank" rel="noopener noreferrer"
        >
          <span>${hit.url}</span>
        </a>
      </section>
    </div>
  `;
}

export default function ({ showMap, google }) {
  const [openInfoWindows, setOpenInfoWindows] = React.useState([]);

  /**
   * Creates a custom GMap marker. Needs access to openInfoWindows state.
   *
   * @param {Object} hit – Algolia search hit.
   * @param {Object} google – A reference to the global google object.
   * @return {Object} – The <CustomMarker> marker for the Google map.
   */
  function createMarker(hit, google) {
    const infoWindow = new google.maps.InfoWindow();
    return (
      <CustomMarker
        key={hit.objectID}
        className={`gmap-marker-${hit.objectID}`}
        hit={hit}
        anchor={{
          x: 0,
          y: -32,
        }}
        onClick={({ marker }) => {
          // Close any open window
          if (openInfoWindows.length > 0) {
            openInfoWindows[0].close();
            openInfoWindows.shift();
            setOpenInfoWindows(openInfoWindows);
          }

          // Open window
          infoWindow.setContent(infoWindowContent(hit));
          infoWindow.open(marker.map, marker);

          // Store state
          openInfoWindows.push(infoWindow);
          setOpenInfoWindows(openInfoWindows);
        }}>
        <div title={hit.verein}>
          <SVGIcon
            style={{
              fill: '#ff7b5c',
              fillOpacity: '1',
              stroke: '#b50004',
              strokeWidth: '1px',
              width: '40px',
              height: '40px',
            }}
            shape="place"
          />
        </div>
      </CustomMarker>
    );
  }

  return (
    <div className={styles.gMapContainer}>
      {showMap ? (
        <GeoSearch
          google={google}
          mapTypeId={google.maps.MapTypeId.ROADMAP}
          // fullscreenControl
          styles={[{ stylers: [{ hue: '#000fad' }] }]}
          initialZoom={5}
          initialPosition={{
            lat: 51.164305,
            lng: 10.4541205,
          }}
          zoomControlOptions={{
            position: google.maps.ControlPosition.LEFT_BOTTOM,
          }}
          enableRefine={false}
          enableRefineOnMapMove={false}>
          {({ hits }) => (
            <>
              {hits.map((hit) => {
                return createMarker(hit, google);
              })}
            </>
          )}
        </GeoSearch>
      ) : (
        <CircularProgress
          size={48}
          style={{
            color: '#ff7b5c',
            marginLeft: '-24px',
            marginTop: '-24px',
            position: 'absolute',
            top: '50vh',
            left: '50vw',
          }}
        />
      )}
    </div>
  );
}
