/* React Tinongo <SVGIcon> component */

import React from 'react';
import classNames from 'classnames';

import styles from './svg-icon.module.scss';

/* <SVGIcon> component */
function SVGIcon({ ...props }) {
  const classes = classNames({
    [`${styles.svgIcon}`]: true,
    [`${styles.svgIconDark}`]: !props.light,
    [`${styles.svgIconLight}`]: props.light,
    [`${styles.svgIconSmall}`]: props.small,
    [`${styles.svgIconMedium}`]: props.medium,
    [`${styles.svgIconBig}`]: props.big,
    [`${styles.svgIconAnimated}`]: props.animated,
    [` ${props.animation}`]: props.animation,
  });

  let icon = <span>{props.shape}</span>;
  switch (props.shape) {
    case 'arrowDown':
      icon = (
        <svg
          {...props}
          className={classes}
          fill="#000000"
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
          <path d="M0-.75h24v24H0z" fill="none" />
        </svg>
      );
      break;

    case 'filterList':
      icon = (
        <svg
          {...props}
          className={classes}
          fill="#000000"
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      );
      break;

    case 'search':
      icon = (
        <svg
          {...props}
          className={classes}
          fill="#000000"
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
          <path d="M0 0h24v24H0V0z" fill="none" />
        </svg>
      );
      break;

    case 'locationSearch':
      icon = (
        <svg
          {...props}
          className={classes}
          fill="#000000"
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M20.94 11c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
          <path d="M0 0h24v24H0V0z" fill="none" />
        </svg>
      );
      break;

    case 'place':
      icon = (
        <svg
          {...props}
          className={classes}
          fill="#000000"
          height="24"
          width="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z" />
        </svg>
      );
      break;

    default:
      break;
  }
  return icon;
}

export default SVGIcon;
