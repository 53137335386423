//
// Gatsby Tinongo Suche > Verein page
// Route: /suche/vereine
//

import React, { useState } from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { graphql } from 'gatsby';
import algoliasearch from 'algoliasearch/lite';
// import qs from 'qs';
import { capitalize } from '../../utils';
import { UIContext } from '../../context/ui';

import ActionButton from '../../components/action-button';
import AppShell from '../../components/app-shell';
import ListIcon from '@material-ui/icons/List';
import Hits from '../../components/algolia/clubs/hits';
import Map from '../../components/algolia/clubs/map';
import Drawer from '../../components/drawer';
import Scrollbox from '../../components/scrollbox';
import SearchBox from '../../components/algolia/clubs/search-box';
import SEO from '../../components/seo';

import styles from './vereine.module.scss';

export const query = graphql`
  query {
    site {
      siteMetadata {
        algoliaAppId
        algoliaApiKey
        algoliaIndexClubs
      }
    }
  }
`;

/**
 * Parses the search query from a given URL path.
 *
 * @param {Object} location
 * @param {string} location.pathname – The URL path.
 * @return {Object} searchState – An Algolia searchState object.
 * @return {string} searchState.query –  The search query.
 */
function urlToSearchState({ pathname }) {
  // Last element of the URL path is the query string, second last the key
  const splittedPath = pathname.split('/');
  const query = decodeURIComponent(splittedPath[splittedPath.length - 1]);
  const key = decodeURIComponent(splittedPath[splittedPath.length - 2]);

  return query === 'vereine' || key === 'vereine'
    ? { query: '' }
    : { query: capitalize(query) };
}

/**
 * Parses attr parameter of the URL and sets its value as the
 * restrictSearchableAttributes of the Algolia <Configure /> widget.
 *
 * @param {Object} config – A configuration object with Algolia search parameters.
 * @param {Object} location
 * @param {string} location.pathname – The URL path.
 * @return {Object} config – A configuration object with Algolia search parameters.
 * @return {string} config.restrictSearchableAttributes – The only Algolia attribute to search in.
 * @return {boolean} config.typoTolerance – No typo tolerance for initial URL based search.
 */
function urlToConfigState(config, { pathname }) {
  // Parse the attr URL parameter
  // const { attr } = qs.parse(search, {
  //   parameterLimit: 1,
  //   ignoreQueryPrefix: true,
  // });

  // Second last element of the URL path is the Algolia attribute of interest
  const splittedPath = pathname.split('/');
  const attr = decodeURIComponent(splittedPath[splittedPath.length - 2]);

  switch (attr) {
    case 'sportart':
      config['restrictSearchableAttributes'] = 'sportarten';
      break;

    case 'plz':
      config['restrictSearchableAttributes'] = 'plz';
      break;

    case 'name':
      config['restrictSearchableAttributes'] = 'verein';
      break;

    default:
      break;
  }

  config['typoTolerance'] = false;
  return config;
}

export default function Clubs({ data, location }) {
  const searchClient = algoliasearch(
    data.site.siteMetadata.algoliaAppId,
    data.site.siteMetadata.algoliaApiKey
  );
  const [searchState, setSearchState] = useState(urlToSearchState(location));
  const [configState, setConfigState] = useState(
    urlToConfigState({ hitsPerPage: 50 }, location)
  );

  const [showMap, setShowMap] = React.useState(null);
  const [google, setGoogle] = React.useState(null);
  const [geocoder, setGeocooder] = React.useState(null);

  React.useEffect(() => {
    let interval;

    if (!google) {
      interval = setInterval(checkForGoogle, 10);
    }

    function checkForGoogle() {
      if (window.google) {
        setGoogle(window.google);
        setGeocooder(new window.google.maps.Geocoder());
        setShowMap(true);
        clearInterval(interval);
      }
    }
  }, [google]);

  const searchStateIsSportart =
    searchState.query && location.pathname.search(/\/sportart\//) !== -1;

  const onSearchStateChange = (newSearchState) => {
    setSearchState(newSearchState);

    if (configState['restrictSearchableAttributes']) {
      delete configState['restrictSearchableAttributes'];
    }

    if (configState['typoTolerance'] === false) {
      configState['typoTolerance'] = 'min';
    }

    setConfigState(configState);
  };

  const onRadiusSearch = ({ latLng = '', radius = '' } = {}) => {
    setConfigState((prevState) => {
      return {
        ...prevState,
        aroundLatLng: `${latLng}`,
        aroundRadius: radius,
      };
    });
  };

  return (
    <>
      {searchStateIsSportart ? (
        <SEO
          title={`${searchState.query} Vereine in Deutschland`}
          description={`Vereinsübersicht Sportart ${searchState.query}. Hier findest Du für die Sportart ${searchState.query} den passenden Verein für Dein Kind.`}
        />
      ) : (
        <SEO
          title="Suche Verein"
          description="Hier findest Du den passenden Sportverein für Dein Kind in der Nähe. Suche nach Sportart, Verein oder Postleitzahl."
        />
      )}

      <AppShell>
        <UIContext.Consumer>
          {(ui) => (
            <>
              <InstantSearch
                indexName={data.site.siteMetadata.algoliaIndexClubs}
                searchClient={searchClient}
                searchState={searchState}
                onSearchStateChange={onSearchStateChange}>
                <Configure {...configState} />

                <Map google={google} showMap={showMap} />
                <SearchBox
                  state={searchState}
                  geocoder={geocoder}
                  onRadiusSearch={onRadiusSearch}
                />

                {ui.layout === 'desktop' && (
                  <aside className={styles.sidePanel}>
                    <div className={styles.sidePanelHits}>
                      <Scrollbox>
                        <Hits />
                      </Scrollbox>
                    </div>
                  </aside>
                )}

                {ui.layout === 'mobile' && (
                  <Drawer
                    fullScreen
                    enterBottom
                    isOpen={ui.actionDrawerIsOpen}
                    closeDrawer={ui.closeActionDrawer}>
                    <div className={styles.sidePanelHits}>
                      <Scrollbox>
                        <Hits />
                      </Scrollbox>
                    </div>
                  </Drawer>
                )}
              </InstantSearch>

              {ui.layout === 'mobile' && (
                <ActionButton label="list" onClick={ui.openActionDrawer}>
                  <ListIcon />
                </ActionButton>
              )}
            </>
          )}
        </UIContext.Consumer>
      </AppShell>
    </>
  );
}
